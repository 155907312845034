<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :total-count="items.length"
    :server-items-length="items.length"
    hide-default-footer
    dense
  >
    <template #top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{ shop && shop.name }}: 本日の次回予約（店内予約）
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text :to="{ name: 'reservation-today' }" exact="">
            店舗選択
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container>
        <small>最終取得日時: {{ lastFetch | datetime }}</small>
        <small>合計: {{ totalCount }} </small>
      </v-container>
    </template>

    <template #item.nameKana="{ item }">
      {{ item.familyNameKana }}
      {{ item.firstNameKana }}
    </template>

    <template #item.createdAt="{ item }">
      {{ item.createdAt | datetime }}
    </template>

    <template #footer>
      <infinite-loading @infinite="infiniteHandler" />
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment'
import padStart from 'lodash/padStart'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'ReservingTodayInShop',
  components: {
    InfiniteLoading,
  },
  filters: {
    startTime(item) {
      let { startHour, startMinute } = item
      return `${padStart(startHour, 2, '0')}:${padStart(startMinute, 2, '0')}`
    },
  },
  props: {
    shopId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: '会員番号',
        value: 'customerCode',
      },
      {
        text: '氏名（カナ）',
        value: 'nameKana',
      },
      // {
      //   text: 'セイ',
      //   value: '_embedded.reserver.familyNameKana',
      // },
      // {
      //   text: 'メイ',
      //   value: '_embedded.reserver.firstNameKana',
      // },
      // {
      //   text: '状態',
      //   value: '_embedded.statusText',
      // },
      {
        text: '予約日',
        value: 'date',
      },
      {
        text: '予約時間',
        value: 'time',
      },
      {
        text: '予約経路',
        value: 'reservingWayText',
      },
      {
        text: '登録日時',
        value: 'createdAt',
      },
    ],
    idToReserve: {},
    shop: null,
    lastFetch: null,
    totalCount: 0,
  }),
  computed: {
    items() {
      let items = Object.values(this.idToReserve)
      items.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      return items
    },
    params() {
      return {
        shop: this.shopId,
        fromCreated: moment()
          .startOf('day')
          .toISOString(),
        // reservedBy:  6,
        reserveInShop: true,
        active: true,
      }
    },
  },
  created() {
    this.fetchShop()
    this.fetchCount()
  },
  mounted() {
    this._timerId = setInterval(this.fetchLatests, 60 * 1000)
  },
  beforeDestroy() {
    if (this._timerId) clearInterval(this._timerId)
  },
  methods: {
    async fetchShop() {
      this.shop = await this.$api.shops(this.shopId).get()
    },
    async fetchCount() {
      try {
        const count = await this.$api.reservations().count(this.params)
        this.totalCount = count
      } catch (err) {
        this.$toast.error('予約件数の取得に失敗しました。')
      }
    },
    async fetchLatests() {
      try {
        let [{ results }, totalCount] = await Promise.all([
          this.$api.reservations().list(this.params),
          this.$api.reservations().count(this.params),
        ])
        this.totalCount = totalCount
        results.forEach(x => {
          this.$set(this.idToReserve, x.id, x)
        })
        this.lastFetch = moment().toISOString()
      } catch (err) {
        console.error(err)
        this.$toast.error('予約データの取得に失敗しました。')
      }
    },
    async infiniteHandler($state) {
      try {
        let { results, next } = await (this.next
          ? this.$api.http.get(this.next)
          : this.$api.reservations().list(this.params))
        results.forEach(x => {
          this.$set(this.idToReserve, x.id, x)
        })
        this.next = next
        if (results.length > 0) $state.loaded()
        if (!next) $state.complete()
      } catch (err) {
        console.error(err)
        $state.error()
      }
    },
  },
  metaInfo() {
    return { title: '本日の次回予約' }
  },
}
</script>
